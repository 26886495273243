import { Provider } from "next-auth/client";
import React from "react";

export function withSession<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithSession = (props: P) => (
    <Provider>
      <WrappedComponent {...(props as P)} />
    </Provider>
  );

  return WithSession;
}
