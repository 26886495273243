import Content from "@components/landing-page/enrollment";
import { Meta } from "@components/layout";
import { withSession } from "@hocs/with-session";

const LandingPage = () => {
  return (
    <>
      <Meta
        metaData={{
          title:
            "The React Job Simulator - Get on the waitlist to join the next cohort",
          imageUrl: "/images/landing-page-open-graph-image.png",
        }}
      />
      <Content />
    </>
  );
};

export default withSession(LandingPage);
